import React from 'react'
import { Menu } from 'antd'
import logo from '../assets/logo.png'
import styled from 'styled-components'

const NavbarMenu = styled(Menu)`
  background-color: #01A2C8;
  line-height: unset!important;
`

const LogoImg = styled.img`
  height: 62px;
  width: 158px;
  margin-top: 3px;
`

const desktopItems = [
  {
    label: '',
    key: 'home',
    icon: <LogoImg src={logo} alt="ifcv-logo" loading="lazy" />
  }
]

const Navbar = () => {
  return <>
    <NavbarMenu selectedKeys={[]} mode="horizontal" items={desktopItems} />
  </>
}
export default Navbar
