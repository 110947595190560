/* eslint-disable camelcase */
import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import { AppContextProvider } from './contexts/AppContext'
import frFR from 'antd/es/locale/fr_FR'

import Layout from './components/Layout'
import Enrollment from './pages/Enrollment'

const queryClient = new QueryClient()

const theme = {
  components: {
    Table: {
      cellPaddingBlockSM: 3,
      cellPaddingInlineSM: 15
    },
    Tabs: {
      inkBarColor: 'purple',
      itemSelectedColor: '#fff',
      itemHoverColor: '#000'
    }
  },
  token: {
    colorPrimary: '#01A2C8',
    fontFamily: 'Eina'
  }
}

function App () {
  return (
    <ConfigProvider theme={theme} locale={frFR}>
        <BrowserRouter>
          <Layout>
            <QueryClientProvider client={queryClient}>
              <AppContextProvider>
                <Routes>
                  <Route path="/" element={<Enrollment />} />
                </Routes>
              </AppContextProvider>
            </QueryClientProvider>
          </Layout>
        </BrowserRouter>
  </ConfigProvider>
  )
}

export default App
