/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, Checkbox, DatePicker, Form, Input, Modal, Select, Table, Typography, Upload } from 'antd'
import { useAppContext } from '../contexts/AppContext'
import { CloudUploadOutlined, CheckCircleTwoTone } from '@ant-design/icons'
import Success from '../components/Success'
import locale from 'antd/es/date-picker/locale/fr_FR'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

import extensions from '../shared/const/extensions.json'
import AboutUs from '../components/AboutUs'
import styled from 'styled-components'

const { Option } = Select
const { Text } = Typography

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 15px;
`

const UploadInput = ({ file, fileType, selectedFiles, onUpload }) => {
  const selectedFile = selectedFiles[fileType]

  if (selectedFile.uploaded) {
    return (
      <h6 style={{ margin: 0 }}>
        <CheckCircleTwoTone size={30} twoToneColor="#52c41a" style={{ marginRight: 5 }}/>{selectedFile.file.name}
      </h6>
    )
  }

  return <Form.Item name={file.key} style={{ margin: 0 }}>
        <Upload
            maxCount={1}
            name='attachment'
            customRequest={(options) => onUpload(options, fileType)}
            accept={extensions.join(',')}
            >
            <Button icon={<CloudUploadOutlined />} size='small' type='link'>Ajouter</Button>
        </Upload>
    </Form.Item>
}

const onChange = (value) => {
  console.log(`selected ${value}`)
}
const onSearch = (value) => {
  console.log('search:', value)
}

// Filter `option.label` match the user type `input`
const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const data = {
  cv: {
    key: 'cv',
    attachmentType: 'CV'
  },
  cni: {
    key: 'cni',
    attachmentType: "Carte nationale d'identité"
  },
  socialSecurityCard: {
    key: 'socialSecurityCard',
    attachmentType: 'Carte vitale'
  },
  otherDoc: {
    key: 'otherDoc',
    attachmentType: 'Autre document'
  }
}

const Enrollment = () => {
  const [uploaded, setUploaded] = useState(data)
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [citySuggestions, setCitySuggestions] = useState([])

  const [form] = Form.useForm()
  const postalCode = Form.useWatch('postalCode', form)

  const formRef = useRef()

  const isSmall = useMediaQuery({ maxWidth: 1000 })
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const { referrals, trainings, saveCandidate } = useAppContext()

  const uploadFileHandler = async (options, fileType) => {
    const { file } = options
    const uploadedCopy = { ...uploaded }
    uploadedCopy[fileType] = { ...uploadedCopy[fileType], uploaded: true, file }
    console.log(uploadedCopy[fileType])
    setUploaded(uploadedCopy)
  }

  const columns = [
    {
      title: '',
      dataIndex: 'attachmentType'
    },
    {
      title: 'Pièces jointes',
      dataIndex: 'attachment',
      render: (_, { key }) => <UploadInput
        // onChange={fileUploadStateChangeHandler}
        onUpload={uploadFileHandler}
        uniqueId='ifcv'
        file={uploaded[key]}
        fileType={key}
        selectedFiles={uploaded}
      />
    }
  ]

  const onFinish = async (formValues) => {
    setSubmitting(true)
    await saveCandidate(formValues, uploaded)
    setSubmitted(true)
    setSubmitting(false)
  }

  const closeModalHandler = () => {
    setSubmitted(false)
    formRef.current.resetFields()
    setUploaded(data)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const getCitySuggestions = async () => {
      if (!postalCode || postalCode.length < 5) return

      const resp = await axios.get(`${process.env.REACT_APP_GEO_API_BASE_URL}?codePostal=${postalCode}`)

      setCitySuggestions(resp.data)
    }
    getCitySuggestions()
  }, [postalCode])

  const itemWidth = isMobile ? '100%' : 'calc(50% - 8px)'

  return (
    <Wrapper>
      <Card style={{ marginBottom: '20px' }}>
            <Modal open={submitted} onCancel={closeModalHandler} footer={null}>
              <Success onClose={closeModalHandler} />
            </Modal>
            <h2>{"Formulaire d'inscription"}</h2>
            <Form
              ref={formRef}
              form={form}
              onFinish={onFinish}
              layout="vertical"
              variant="filled"
              style={{ width: isMobile ? '100%' : 600 }}
            >
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        name="gender" label="Civilité" rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(20% - 8px)', marginRight: '8px' }}
                    >
                      <Select placeholder="Civilité" size='large'>
                          <Option value={1}>Monsieur</Option>
                          <Option value={2}>Madame</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                        label="Prénom"
                        name="firstName"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(40% - 8px)' }}
                    >
                        <Input placeholder="Votre prénom" size='large' />
                    </Form.Item>
                    <Form.Item
                        label="Nom"
                        name="surname"
                        rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: isMobile ? '100%' : 'calc(40% - 8px)', margin: '0 8px' }}
                    >
                        <Input placeholder="Votre nom" size='large' />
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ margin: '15px 0 0 0' }}>
                    <Form.Item label="Date de naissance" name="dateOfBirth" rules={[{ required: true }]}
                      style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <DatePicker locale={locale} size='large' format='DD/MM/YYYY' style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="nationality" label="Nationalité" rules={[{ required: true }]}
                        style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}
                    >
                      <Select placeholder="Votre nationalité" size='large'>
                          <Option value={0}>FRANCAISE</Option>
                          <Option value={1}>UNION EUROPEENNE</Option>
                          <Option value={2}>HORS UNION EUROPEENNE</Option>
                      </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Adresse" name="address" rules={[{ required: true }]}>
                        <Input size='large' placeholder='Votre adresse postale' />
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item label="Code postal" name="postalCode" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <Input size='large' placeholder='Code postal' />
                    </Form.Item>
                    <Form.Item label="Ville" name="city" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth }}>
                      <Select placeholder="Ville" size='large'>
                        {citySuggestions.map(({ nom, code }) => (<Option value={nom} key={code}>{nom}</Option>))}
                      </Select>
                    </Form.Item>
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item label="Téléphone" name="telephone" rules={[{ required: true }]}
                               style={{ display: 'inline-block', width: itemWidth, marginRight: '8px' }}>
                        <Input size='large' placeholder='Votre numéro de téléphone' />
                    </Form.Item>
                    <Form.Item label="E-mail" name="email" rules={[{ required: true, type: 'email' }]}
                               style={{ display: 'inline-block', width: itemWidth }}>
                        <Input size='large' placeholder='Votre adresse e-mail' />
                    </Form.Item>
                </Form.Item>

                <Form.Item label="Formation souhaitée" name="idTraining" rules={[{ required: true }]} >
                      <Select
                        showSearch
                        placeholder="Sélectionner la formation souhaitée"
                        size='large'
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={trainings}
                    />
                </Form.Item>

                <Form.Item label="Comment avez-vous connu l'IFCV" name="sourceOfReferral">
                      <Select
                        showSearch
                        placeholder="Comment avez-vous connu l'IFCV"
                        size='large'
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={referrals}
                    />
                </Form.Item>

                <Table
                    columns={columns}
                    dataSource={Object.values(data)}
                    pagination={false}
                    style={{ marginBottom: 30 }}
                    size="small"
                />

                <Form.Item label="Commentaire" name="comment">
                    <Input.TextArea placeholder='Commentaire...' rows={5} />
                </Form.Item>

                <Form.Item label="Consentement RGPD" name="rgpd" valuePropName="checked" layout="horizontal" rules={[{ required: true }]}>
                    <Checkbox>{'J\'accepte'}</Checkbox>
                </Form.Item>
                <Text type="secondary">
                    {'J\'accepte que les informations recueillies sur ce formulaire soient enregistrées par l\'IFCV et conservées pendant 2 ans. Conformément à la loi "informatique et libertés" et au RGPD du 25 mai 2018, vous pouvez exercer votre droit d\'accès aux données vous concernant et les faire rectifier. Pour cela, consultez notre Politique de Confidentialité'}
                </Text>

                <Form.Item style={{ marginTop: 30, textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={submitting} disabled={submitting}>
                      Envoyer ma demande
                    </Button>
                </Form.Item>
            </Form>
        </Card>
        {!isSmall && <AboutUs />}
    </Wrapper>
  )
}

export default Enrollment
