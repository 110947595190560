import { List, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import reasons from '../shared/const/reasons.json'
import { CheckCircleTwoTone } from '@ant-design/icons'

const Title = styled(Typography.Title)`
    text-align: center;
    color: #01A2C8!important;
`

const Reason = styled.span`
    font-size: 14px;
`

export default function WhyChooseIFCV () {
  return (
    <div>
        <Title level={5}>{'Pourquoi rejoindre l\'IFCV ?'}</Title>
        <List
    itemLayout="horizontal"
    dataSource={reasons}
    renderItem={(item, index) => (
      <List.Item>
        <List.Item.Meta
          avatar={<CheckCircleTwoTone twoToneColor="#01A2C8" style={{ fontSize: 18 }} />}
          title={<Reason>{item}</Reason>}
        />
      </List.Item>
    )}
  />
    </div>
  )
}
