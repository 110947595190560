/* eslint-disable prefer-promise-reject-errors */

export const makeDocPayload = ({ formData, file, fileName, extension, fileType, idx = '' }) => {
  return {
    nomDocument: `${fileName}-${formData.firstName} ${formData.surname} ${idx}.${extension}`,
    contenu: file,
    extension: `.${extension}`,
    mimeType: fileType
  }
}

export const getBase64 = (file) => {
  const reader = new FileReader()
  const mimeType = file.type

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file)

    reader.onload = function () {
      resolve(reader.result.replace(`data:${mimeType};base64,`, ''))
    }

    reader.onerror = function (error) {
      reject({ error: error.toString() })
    }
  })
}
