import React from 'react'
import Navbar from './Navbar'
import styled from 'styled-components'

const NavbarWrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
`

const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`

const MainSection = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export default function Layout ({ children }) {
  return (
    <div className="App">
      <NavbarWrapper><Navbar /></NavbarWrapper>
      <MainWrapper>
        <MainSection>{children}</MainSection>
      </MainWrapper>
    </div>
  )
}

Layout.propTypes = {
  children: React.ReactNode
}
