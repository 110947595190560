import React from 'react'
import Testimonies from './Testimonies'
import WhyChooseIFCV from './WhyChooseIFCV'
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export default function AboutUs () {
  return (
    <Wrapper>
        <Testimonies/>
        <WhyChooseIFCV/>
    </Wrapper>
  )
}
