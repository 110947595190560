import { Avatar, Carousel, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import testimonies from '../shared/const/testimonies.json'
import maleFace from '../assets/male_face_placeholder.jpg'
import femaleFace from '../assets/female_face_placeholder.jpg'

const Testimony = styled.div`
    width: 200px;
    height: 340px;
    background-color: #01A2C8AA;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
`

const Content = styled.p`
    font-size: 14px;
    font-style: italic;
    margin-bottom: 10px;
    text-align: justify;
    text-justify: inter-word;
`

const PersonInfo = styled.div`
    display: flex;
    gap: 15px;
    a {
        font-weight: bold;
    }
`

const Title = styled(Typography.Title)`
    text-align: center;
    color: #01A2C8!important;
`

export default function Testimonies () {
  return (
    <div>
        <Title level={5} style={{ textAlign: 'center' }}>Témoignages</Title>
        <Carousel effect="fade" dotPosition="top" autoplaySpeed={6000} autoplay>
            {testimonies.map(({ name, link, title, gender, content }) => (
                <Testimony key={name}>
                    <Content><q>{content}</q></Content>
                    <PersonInfo>
                        <div style={{ width: 40 }}>
                            <Avatar src={gender === 'male' ? maleFace : femaleFace} size={40}/>
                        </div>
                        <div>
                            <Typography.Link level={5} href={link} target='_blank'>{name}</Typography.Link>
                            <Typography.Text type='secondary'>{title}</Typography.Text>
                        </div>
                    </PersonInfo>
                </Testimony>
            ))}
        </Carousel>
    </div>
  )
}
