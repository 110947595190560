import React, { createContext, useContext, useState } from 'react'
import { axiosInstance } from '../shared/axiosInstance'
import { useQuery } from '@tanstack/react-query'

import referrals from '../shared/data/referrals.json'
import { getBase64, makeDocPayload } from '../shared/YpareoDocument'
import dayjs from 'dayjs'

import activeTrainings from '../shared/const/activeTrainings.json'

const trainings = activeTrainings.map(tr => ({ value: tr.code, label: tr.label }))

export const AppContext = createContext()

// eslint-disable-next-line react/prop-types
export const AppContextProvider = ({ children }) => {
  const [selectedEnrollment, selectEnrollment] = useState(null)
  const [resuming, setResuming] = useState(null)

  // const fetchTrainings = async () => {
  //   const res = await axiosInstance.get('/trainings')
  //   return Object.values(res.data)
  //     .filter(training => training.plusUtilise === 0 && training.estFormationLongue === 1)
  //     .map(training => ({ value: training.codeFormation, label: training.etenduFormation }))
  // }

  const fetchSites = async () => {
    const res = await axiosInstance.get('/trainings/sites')
    return Object.values(res.data)
      .map(site => ({ value: site.codeSite, label: site.nomSite }))
  }

  // const { data: trainings = [] } = useQuery({
  //   queryKey: ['trainings'],
  //   queryFn: fetchTrainings
  // })

  const { data: sites = [] } = useQuery({
    queryKey: ['sites'],
    queryFn: fetchSites
  })

  const saveCandidate = async (formData, files) => {
    try {
      // force site to 26
      // Institut de Formation aux Carrières de la Communication et de la Vente
      const defaultSite = sites.find(site => site.value === 26) || { value: 26 }

      const payload = {
        idSite: defaultSite.value.toString(),
        idFormationSouhait1: formData.idTraining.toString(),
        codeCiviliteApprenant: formData.gender,
        nomApprenant: formData.surname,
        prenomApprenant: formData.firstName,
        tel1Appr: formData.telephone,
        emailAppr: formData.email,
        dateNaissance: dayjs(formData.dateOfBirth).format('DD/MM/YYYY'),
        idNationalite: formData.nationality.toString(),
        adresse1Appr: formData.address,
        cpAppr: formData.postalCode,
        villeAppr: formData.city,
        observation: `${formData.sourceOfReferral} | ${formData.comment}`.slice(0, 100)
      }

      /**
       * Save candidate info
       */
      const candidateCreated = await axiosInstance.post('/enrollments', payload)

      const candidateId = candidateCreated.data
      const attachmentsEndpoint = `/enrollments/attachments/${candidateId}`

      /**
       * Upload attachments
       */

      // CV
      if (files.cv.uploaded) {
        const docPayload = makeDocPayload({
          formData,
          file: await getBase64(files.cv.file),
          fileName: 'CV',
          extension: files.cv.file.name.split('.').pop(),
          fileType: files.cv.file.type
        })
        await axiosInstance.post(attachmentsEndpoint, docPayload)
      }
      // ID Card
      if (files.cni.uploaded) {
        const docPayload = makeDocPayload({
          formData,
          file: await getBase64(files.cni.file),
          fileName: 'CNI',
          extension: files.cv.file.name.split('.').pop(),
          fileType: files.cv.file.type
        })
        await axiosInstance.post(attachmentsEndpoint, docPayload)
      }
      // Social Security Card
      if (files.socialSecurityCard.uploaded) {
        const docPayload = makeDocPayload({
          formData,
          file: await getBase64(files.socialSecurityCard.file),
          fileName: 'Carte Vitale',
          extension: files.cv.file.name.split('.').pop(),
          fileType: files.cv.file.type
        })
        await axiosInstance.post(attachmentsEndpoint, docPayload)
      }
      // Other Doc
      if (files.otherDoc.uploaded) {
        const docPayload = makeDocPayload({
          formData,
          file: await getBase64(files.otherDoc.file),
          fileName: 'Autre',
          extension: files.cv.file.name.split('.').pop(),
          fileType: files.cv.file.type
        })
        await axiosInstance.post(attachmentsEndpoint, docPayload)
      }
    } catch (error) {
      console.error('Error posting data to API:', error)
    }
  }

  return (
    <AppContext.Provider value={{
      referrals,
      trainings,
      resuming,
      setResuming,
      selectedEnrollment,
      selectEnrollment,
      saveCandidate
    }}>
        {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
